import './App.css';
import { Fragment, useState } from 'react';
import { Button, TextField, Select, Box, FormGroup, styled, MenuItem, InputAdornment, IconButton, Tooltip, Card, CardContent, Alert, AlertTitle, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff, HelpOutline, AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'

const LegButton = styled(Button)({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.6)',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  }
});

const CardContentNoBottomPadding = styled(CardContent)(`
  padding: unset;
  &:last-child {
    padding-bottom: unset;
  }
`);

const routePattern = '^[a-zA-Z]{3}(-!?[a-zA-Z]{3}){0,5}-[a-zA-Z]{3}$';
const onRouteInvalid = (e) => e.target.setCustomValidity('Origin-Dest airport codes, e.g. YYZ-SFO.\nForce connection airports (at most 2), e.g. YYZ-YVR-SFO.\nExclude connection airports (at most 3), e.g. YYZ-!YYC-SFO.');
const resetCustomValidityMessage = (e) => {
  if (!e.target.validity.patternMismatch) {
    e.target.setCustomValidity('');
  }
};

const ToggleDetails = (e) => {
  e.target.closest('.availability-result').classList.toggle('expanded');
};

const MiddleInputField = styled(TextField)({
  '& fieldset': { borderRadius: 0 }
});

const RightInputField = styled(TextField)({
  '& fieldset': { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
});

function AvailabilityBound(props) {
  let bound = props.bound;
  return (
    <Card sx={{ mt: 0.5, mb: 0.5, pl: 1, pr: 1 }} className='availability-result' style={{ position: 'relative' }}>
      <IconButton style={{ height: '28px', width: '28px', right: '5px', position: 'absolute' }} className='expand-button' onClick={ToggleDetails}><AddCircleOutline/></IconButton>
      <IconButton style={{ height: '28px', width: '28px', right: '5px', position: 'absolute' }} className='collapse-button' onClick={ToggleDetails}><RemoveCircleOutline/></IconButton>
      {bound.segments.map((segment, idx) =>
      <Fragment key={`availability-bound-${bound.id}-segment-${idx}`}>
        <CardContentNoBottomPadding sx={{ mt: 0.5, mb: 0.5}}>
          <table>
            <tbody>
              <tr>
                <td style={{ width: '75px', verticalAlign: 'top' }}>{segment.carrierCode}{segment.operating === undefined? '' : ` (${segment.operating.carrierCode})`} {segment.number}</td>
                <td style={{ verticalAlign: 'top' }}> {segment.departure.iataCode} {segment.departure.at.substring(11, 16)} → {segment.arrival.iataCode} {segment.arrival.at.substring(11, 16)}
                <div style={{ fontSize: '13px', padding: '5px 0px', color: '#555555' }}>{segment.availabilityClasses === undefined? segment.closedStatus : segment.availabilityClasses.map((bucket) => `${bucket.class}${bucket.numberOfBookableSeats === undefined? 0 : bucket.numberOfBookableSeats}`).join(' ')}</div></td>
              </tr>
            </tbody>
          </table>
        </CardContentNoBottomPadding>
        <CardContentNoBottomPadding className='availability-results-details'>
          <table>
            <tbody>
              <tr>
                <td style={{ width: '75px', verticalAlign: 'top' }}></td>
                <td style={{ verticalAlign: 'top' }}><label style={{ color: '#555555' }}>Stops: </label>{segment.numberOfStops}</td>
              </tr>
              <tr>
                <td style={{ width: '75px', verticalAlign: 'top' }}></td>
                <td style={{ verticalAlign: 'top' }}><label style={{ color: '#555555' }}>Aircraft: </label>{segment.aircraft.code}</td>
              </tr>
            </tbody>
          </table>
        </CardContentNoBottomPadding>
      </Fragment>
      )}
    </Card>
  )
}

function AvailabilityLeg(props) {
  let bounds = props.bounds;
  let [date, route] = props.queryMetadata;
  if (bounds.length === 0 && (date === '' || route === '')) {
    return;
  }
  let airports = route.split('-');
  let originDestination = `${airports[0]}-${airports[1]}`;
  let connectingAirports = [];
  let nonConnectingAirports = [];
  airports.slice(1, airports.length - 1).forEach((airport) => {
    if (airport[0] === '!') {
      nonConnectingAirports.push(airport.substring(1, airport.length));
    } else {
      connectingAirports.push(airport);
    }
  });
  let timestamp = new Date().getTime();
  return (
    <div>
      <Card style={{ backgroundColor: '#2C387E', padding: '5px 12px 5px 12px' }}>
        <Typography style={{
          fontWeight: 'bold',
          fontSize: '18px',
          color: '#FFFFFF',
          minHeight: '3px',
          display: 'inline-block',
        }}>Flight Availability</Typography>
        <Typography style={{
          float: 'right',
          textAlign: 'right',
          color: '#FFFFFF',
          fontWeight: 'bold',
          fontSize: '13px',
          padding: '5px 0px',
          lineHeight: 'initial',
          display: 'inline-block',
        }}>
          <div> {originDestination} on {date} </div>
          {props.includeExclude !== ''?
          <div> {props.isInclude? 'Flying' : 'Excluding'} {props.includeExclude}</div>
          :
          null}
          {connectingAirports.length > 0?
          <div> Connecting at {connectingAirports.join(' ')} </div>
          :
          null}
          {nonConnectingAirports.length > 0?
          <div> Avoiding {nonConnectingAirports.join(' ')} </div>
          :
          null}
        </Typography>
      </Card>
      <div>
        {bounds.length === 0?
        <Card sx={{ mt: 0.5, mb: 0.5, pl: 1, pr: 1 }}>
          <CardContentNoBottomPadding sx={{ m: 1}}>
            <div style={{ fontSize: '15px' }}> No Entries </div>
          </CardContentNoBottomPadding>
        </Card>
        :
        bounds.map((bound) => <AvailabilityBound bound={bound} key={`availability-bound-${bound.id}-${timestamp}`} />)}
      </div>
    </div>
  )
}

function Availability(props) {
  let data = props.data;
  if (data === null) return;
  let queryMetadata = props.queryMetadata;
  let dataByLeg = [[], [], [], [], [], []];
  if (data.data !== undefined) {
    data.data.forEach(bound => {
      let legIdx = parseInt(bound.originDestinationId);
      dataByLeg[legIdx].push(bound);
    });
  }
  dataByLeg.forEach(leg => {
    leg.sort((a, b) => a.segments.length - b.segments.length);
  });
  return (
    <Fragment>
      {dataByLeg.map((bounds, idx) => <AvailabilityLeg bounds={bounds} queryMetadata={queryMetadata[idx]} isInclude={props.isInclude} includeExclude={props.includeExclude} key={`availability-${idx}`}/>)}
    </Fragment>
  )
}

function App() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInclude, setIsInclude] = useState(true);
  const [includeExclude, setIncludeExclude] = useState('');
  const [showApiKeyHelperText, setShowApiKeyHelperText] = useState(false);
  const [showApiSecret, setShowApiSecret] = useState(false);
  const [authError, setAuthError] = useState('');
  const [availabilityErrors, setAvailabilityErrors] = useState([]);
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [date3, setDate3] = useState('');
  const [date4, setDate4] = useState('');
  const [date5, setDate5] = useState('');
  const [date6, setDate6] = useState('');
  const [route1, setRoute1] = useState('');
  const [route2, setRoute2] = useState('');
  const [route3, setRoute3] = useState('');
  const [route4, setRoute4] = useState('');
  const [route5, setRoute5] = useState('');
  const [route6, setRoute6] = useState('');

  const setStates = (e) => {
    setIncludeExclude(e.target.elements['includeExclude'].value.toUpperCase());
    e.target.elements['date[]'].forEach((dateElement, idx) => {
      let date = dateElement.value;
      let route = e.target.elements['route[]'][idx].value.toUpperCase();
      switch (idx) {
        case 0:
          setDate1(date);
          setRoute1(route);
          break;
        case 1:
          setDate2(date);
          setRoute2(route);
          break;
        case 2:
          setDate3(date);
          setRoute3(route);
          break;
        case 3:
          setDate4(date);
          setRoute4(route);
          break;
        case 4:
          setDate5(date);
          setRoute5(route);
          break;
        case 5:
          setDate6(date);
          setRoute6(route);
          break;
        default:
          break;
      }
    });
  };

  const availability = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let amadeusApiKey = e.target.elements['amadeusApiKey'].value;
    let amadeusApiSecret = e.target.elements['amadeusApiSecret'].value;
    let authorizationResp = await fetch('https://api.amadeus.com/v1/security/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=client_credentials&client_id=${amadeusApiKey}&client_secret=${amadeusApiSecret}`,
    });
    let authorization = await authorizationResp.json();
    let accessToken;
    if (authorizationResp.status === 200) {
      accessToken = authorization.access_token;
      localStorage.setItem('amadeus_api_key', amadeusApiKey);
      localStorage.setItem('amadeus_api_secret', amadeusApiSecret);
      setAuthError('');
    } else {
      setIsLoading(false);
      setAuthError(`${authorization.error_description} (${authorization.error}, ${authorization.code})`)
      return;
    }
    let originDestinations = [];
    e.target.elements['date[]'].forEach((dateElement, idx) => {
      let date = dateElement.value;
      let route = e.target.elements['route[]'][idx].value.toUpperCase();
      if (date === '' || route === '') {
        return;
      }
      let airports = route.split('-');
      let originDestination = {
        id: idx.toString(),
        originLocationCode: airports[0],
        destinationLocationCode: airports[airports.length - 1],
        departureDateTime: {
          date: date,
        },
      };
      let includedConnectionPoints = [];
      let excludedConnectionPoints = [];
      airports.slice(1, airports.length - 1).forEach((airport) => {
        if (airport.startsWith('!')) {
          excludedConnectionPoints.push(airport.substring(1, airport.length));
        } else {
          includedConnectionPoints.push(airport);
        }
      });
      if (includedConnectionPoints.length > 0) {
        originDestination.includedConnectionPoints = includedConnectionPoints;
      }
      if (excludedConnectionPoints.length > 0) {
        originDestination.excludedConnectionPoints = excludedConnectionPoints;
      }
      originDestinations.push(originDestination);
    });
    let body = {
      originDestinations: originDestinations,
      travelers: [{id: '1', travelerType: 'ADULT'}],
      sources: ['GDS'],
      searchCriteria: {
        flightFilters: {
          carrierRestrictions: {},
        },
        includeClosedContent: true,
      },
    };
    let includedExcludedAirlineCodes = e.target.elements['includeExclude'].value.toUpperCase();
    if (includedExcludedAirlineCodes !== '') {
      if (e.target.elements['isInclude'].value === 'true') {
        body.searchCriteria.flightFilters.carrierRestrictions.includedCarrierCodes = includedExcludedAirlineCodes.split(',');
      } else {
        body.searchCriteria.flightFilters.carrierRestrictions.excludedCarrierCodes = includedExcludedAirlineCodes.split(',');
      }
    }
    const availabilityResp = await fetch('https://api.amadeus.com/v1/shopping/availability/flight-availabilities', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const availability = await availabilityResp.json();
    if (availabilityResp.status === 200) {
      setData(availability);
      setAvailabilityErrors([]);
    } else {
      setData(null);
      setAvailabilityErrors(availability.errors)
    }
    setStates(e);
    setIsLoading(false);
    window.scrollTo({ top: 0, behavior: 'instant' });
  };

  return (
    <div>
      {authError !== ''? <Alert severity='error'>{authError}</Alert> : null}
      {availabilityErrors.map((error) =>
      <Alert severity='error'>
        <AlertTitle>{error.title} ({error.status})</AlertTitle>
        {error.detail} ({error.code})
      </Alert>
      )}
      <Availability data={data} isInclude={isInclude} includeExclude={includeExclude} queryMetadata={[[date1, route1], [date2, route2], [date3, route3], [date4, route4], [date5, route5], [date6, route6]]}/>
      <Box display='flex' justifyContent='center'><Box component='form' onSubmit={availability} autoComplete='off' maxWidth='384px'>
        <FormGroup row sx={{ m: 1, mt: 2, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <LegButton variant='outlined' disabled>Leg1</LegButton>
          <MiddleInputField name='date[]' label='Date' variant='outlined' type='date' style={{ width: '195px' }} inputProps={{ pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}' }} InputLabelProps={{ shrink: true}} defaultValue={date1} required disabled={isLoading} />
          <RightInputField name='route[]' label='Route' variant='outlined' inputProps={{ pattern: routePattern, onInvalid: onRouteInvalid, style: { textTransform: 'uppercase' } }} InputLabelProps={{ shrink: true}} defaultValue={route1} onChange={resetCustomValidityMessage} required disabled={isLoading} />
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <LegButton variant='outlined' disabled>Leg2</LegButton>
          <MiddleInputField name='date[]' label='Date' variant='outlined' type='date' style={{ width: '195px' }} inputProps={{ pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}' }} InputLabelProps={{ shrink: true}} defaultValue={date2} disabled={isLoading} />
          <RightInputField name='route[]' label='Route' variant='outlined' inputProps={{ pattern: routePattern, onInvalid: onRouteInvalid, style: { textTransform: 'uppercase' } }} InputLabelProps={{ shrink: true}} defaultValue={route2} onChange={resetCustomValidityMessage} disabled={isLoading} />
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <LegButton variant='outlined' disabled>Leg3</LegButton>
          <MiddleInputField name='date[]' label='Date' variant='outlined' type='date' style={{ width: '195px' }} inputProps={{ pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}' }} InputLabelProps={{ shrink: true}} defaultValue={date3} disabled={isLoading} />
          <RightInputField name='route[]' label='Route' variant='outlined' inputProps={{ pattern: routePattern, onInvalid: onRouteInvalid, style: { textTransform: 'uppercase' } }} InputLabelProps={{ shrink: true}} defaultValue={route3} onChange={resetCustomValidityMessage} disabled={isLoading} />
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <LegButton variant='outlined' disabled>Leg4</LegButton>
          <MiddleInputField name='date[]' label='Date' variant='outlined' type='date' style={{ width: '195px' }} inputProps={{ pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}' }} InputLabelProps={{ shrink: true}} defaultValue={date4} disabled={isLoading} />
          <RightInputField name='route[]' label='Route' variant='outlined' inputProps={{ pattern: routePattern, onInvalid: onRouteInvalid, style: { textTransform: 'uppercase' } }} InputLabelProps={{ shrink: true}} defaultValue={route4} onChange={resetCustomValidityMessage} disabled={isLoading} />
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <LegButton variant='outlined' disabled>Leg5</LegButton>
          <MiddleInputField name='date[]' label='Date' variant='outlined' type='date' style={{ width: '195px' }} inputProps={{ pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}' }} InputLabelProps={{ shrink: true}} defaultValue={date5} disabled={isLoading} />
          <RightInputField name='route[]' label='Route' variant='outlined' inputProps={{ pattern: routePattern, onInvalid: onRouteInvalid, style: { textTransform: 'uppercase' } }} InputLabelProps={{ shrink: true}} defaultValue={route5} onChange={resetCustomValidityMessage} disabled={isLoading} />
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <LegButton variant='outlined' disabled>Leg6</LegButton>
          <MiddleInputField name='date[]' label='Date' variant='outlined' type='date' style={{ width: '195px' }} inputProps={{ pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}' }} InputLabelProps={{ shrink: true}} defaultValue={date6} disabled={isLoading} />
          <RightInputField name='route[]' label='Route' variant='outlined' inputProps={{ pattern: routePattern, onInvalid: onRouteInvalid, style: { textTransform: 'uppercase' } }} InputLabelProps={{ shrink: true}} defaultValue={route6} onChange={resetCustomValidityMessage} disabled={isLoading} />
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <Select sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} name='isInclude' value={isInclude} onChange={(e) => setIsInclude(e.target.value)}>
            <MenuItem value={true}>Include</MenuItem>
            <MenuItem value={false}>Exclude</MenuItem>
          </Select>
          <MiddleInputField name='includeExclude' label={`${isInclude? 'Included' : 'Excluded'} Airline Codes`} variant='outlined' style={{ width: '100%' }} inputProps={{ pattern: '^[0-9a-zA-Z]{2}(,[0-9a-zA-Z]{2})*$', onInvalid: (e) => e.target.setCustomValidity('Comma-separated airline codes, e.g. AC,LH'), style: { textTransform: 'uppercase' } }} InputLabelProps={{ shrink: true}} defaultValue={includeExclude} onChange={resetCustomValidityMessage} disabled={isLoading} />
          <LoadingButton sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} type='submit' variant='contained' loading={isLoading}>Search</LoadingButton>
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <TextField
            name='amadeusApiKey'
            label='Amadeus API Key'
            style={{ width: '100%' }}
            InputProps={{
              endAdornment:
                <InputAdornment position='end'>
                  <Tooltip
                    title={
                      <div>
                        <a href='https://developers.amadeus.com/register' target='_blank' rel='noreferrer'>Register</a> your own Amadeus developer account.
                        <br/>
                        Then follow <a href="https://developers.amadeus.com/self-service/apis-docs/guides/moving-to-production-743" target='_blank' rel='noreferrer'>this guide</a> to obtain your <b>production</b> API Key and Secret.
                      </div>
                    }
                    open={showApiKeyHelperText}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton onClick={() => setShowApiKeyHelperText((show) => !show)}>
                      <HelpOutline/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
            }}
            InputLabelProps={{ shrink: true}}
            disabled={isLoading}
            defaultValue={localStorage.getItem('amadeus_api_key') || ''}
            required
          />
        </FormGroup>
        <FormGroup row sx={{ m: 1, justifyContent: 'center', flexWrap: 'nowrap' }}>
          <TextField
            name='amadeusApiSecret'
            label='Amadeus API Secret'
            style={{ width: '100%' }}
            type={showApiSecret? 'text' : 'password'}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle api secret visibility'
                    onClick={(e) => {setShowApiSecret((show) => !show); e.preventDefault();}}
                  >
                    {showApiSecret? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
            }}
            InputLabelProps={{ shrink: true}}
            disabled={isLoading}
            defaultValue={localStorage.getItem('amadeus_api_secret') || ''}
            required
          />
        </FormGroup>
      </Box></Box>
    </div>
  );
}

export default App;
